.clinic-profile-root {
    width: 320px;
    max-width: 320px;
    min-width: 320px;
    height: 365px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
    box-shadow: rgba(85, 85, 85, 0.15) 0px 3px 6px;
    border: 1px solid rgb(231, 233, 239);
    border-radius: 5px;
}

.clinic-profile-details-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 5px;
    width: 100%;
}

.clinic-profile-details-picture {
    width: 100%;
    height: 127px;
    border-radius: 5px;
}

.clinic-profile-details-hearts {
    display: flex;
    flex-direction: row;
    gap: 0px 1px;
    margin-top: 12px;
}

.heart {
    width: 15px;
    height: 12px;
}

.clinic-profile-details-name {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 700;
    padding: 0;
    margin: 0;
    margin-top: 10px;
}

.clinic-profile-other-info {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-top: 10px;
    padding: 0;
    gap: 5px 0px;
}

.clinic-profile-other-info-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px 2px;
}

.clinic-profile-other-info-item-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.clinic-profile-other-info-item-icon {
    width: 20px;
    min-width: 20px;
    max-width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clinic-profile-other-info-item-description {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    line-height: 12px;
}

.clinic-profile-other-info-item h5 {
    color: rgb(0, 0, 0);
    font-size: 11px;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

.clinic-profile-other-info-item-spec-list {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    margin-left: 4px;
    /*line-height: 12px; /*here*/
    gap: 0px 5px;

}

.clinic-profile-other-info-item-spec-item {
    color: rgb(162, 163, 166);
    font-size: 11px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.clinic-profile-other-info-item-text {
    color: rgb(162, 163, 166);
    font-size: 11px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-left: 4px;
}

.clinic-profile-other-info-item a {
    text-decoration: none;
    font-size: 13px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: #2e69c3;
}

.clinic-profile-other-info-item a:hover {
    text-decoration: underline;
}

.clinic-profile-buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 0px 5px;
    margin-top: 10px;
    width: 100%;
}

.clinic-profile-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0px 2px;
}

.view-clinic {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    border: 1px solid rgb(72, 191, 192);
    background: none;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    transition: 0.2s;
}

.view-clinic svg {
    width: 21px;
    height: 21px;
    fill: rgb(72, 191, 192);
    transition: 0.2s;
}

.view-clinic h5 {
    color: rgb(72, 191, 192);
    font-size: 14px;
    font-weight: 300;
    margin: 0;
    padding: 0;
    transition: 0.2s;
}

.view-clinic:hover {
    background: rgb(72, 191, 192);
    transition: 0.2s;
}
.view-clinic:hover h5 {
    color: white;
    transition: 0.2s;
}
.view-clinic:hover svg {
    fill: white;
    transition: 0.2s;
}

