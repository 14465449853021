@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

* {
    font-family: Mulish;
}

.home-root {
    overflow: hidden;
}

.home-section-intro {
    background-color: rgb(233, 234, 244);
}

.home-main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.home-container {
    margin-top: 50px;
    width: 1195px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.home-appointment-section {
    padding-left: 10px;
}

.home-appointment-header h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.5;
    color: rgb(42, 51, 144);
    max-width: 420px;
}

.home-appointment-buttons {
    margin-top: 35px;
}

.home-appointment-visit {
    display: flex;
    flex-direction: row;
    gap: 0px 25px;
}

.visit-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    padding-bottom: 2px;
    border-radius: 5px;
    border: 0;
    font-size: 15px;
    gap: 10px 0px;
}

.clinic-visit-button {
    background: rgb(42, 51, 144);
    color: white;
}

.video-visit-button {
    background: transparent;
    color: rgb(42, 51, 144);
}

.home-appointment-location {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgb(244, 244, 249);
    width: fit-content;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 15px;
    margin-top: 50px;
    border-radius: 5px;
}

.home-appointment-location-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: rgb(40, 40, 40);
}


.home-appointment-row-container {
    display: flex;
    flex-direction: row;   
    margin-top: 17px;
}

.home-appointment-speciality {
    background: rgb(255, 255, 255);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    border-radius: 5px;
    width: fit-content;
    height: 65px;
}

.home-appointment-speciality-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: rgb(40, 40, 40);
    padding: 5px;
}

.home-appointment-speciality-text {
    width: 310px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.home-appointment-speciality-text .sub-text {
    color: rgb(162, 163, 166);
    font-size: 14px;
}

.home-appointment-search {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 65px;
    width: 188px;
    background: rgb(248, 30, 70);
    margin-left: 15px;
    border-radius: 5px;
    color: white;
}

.home-section-bottom-graphic {
    background: transparent;
    display: block;
}

.home-section-bottom-graphic-mobile {
    background: transparent;
    display: none;
}

.home-all-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 90px;
}

.home-popular-specialties {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 1165px;
}

.home-popular-specialties h2 {
    font-family: Mulish;
    font-size: 20px;
    font-weight: 700;
}

.home-popular-specialties-items {
    display: flex;
    flex-direction: row;
    list-style: none;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    gap: 20px 35px;
    margin: 0;
    margin-top: 10px;
    padding: 0;
}

.home-popular-specialties-item {
    width: 165px;
    height: 165px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: rgb(244, 244, 249);
    border-radius: 5px;
    cursor: pointer;
}

.home-popular-specialties-item svg {
    margin-top: 0px;
}

.home-popular-specialties-item h3 {
    font-family: Mulish;
    font-weight: 600;
    font-size: 14px;
    color: rgb(42, 51, 144) ;
    line-height: 1.5;
    margin-top: 10px;
}

.home-info-section {
    width: 1165px;
    margin-top: 100px;
}

.home-info-section h2 {
    font-family: Mulish;
    font-size: 20px;
    font-weight: 700;
}

.home-info-section-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.home-info-section-buttons {
    display: flex;
    flex-direction: row;
    list-style: none;
    /* justify-content: space-between; */
    gap: 20px 10px;
    margin: 0;
    margin-top: 12px;
    padding: 0;
}

.home-info-section-button {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 5px;
    padding: 5px 5px;
    cursor: pointer;
    height: fit-content;
}

.home-info-section-scroll-buttons {
    display: flex;
    flex-direction: row;
    list-style: none;
    /* justify-content: space-between; */
    gap: 20px 15px;
}

.home-info-section-scroll-button {
    cursor: pointer;
}

.scroll-left {
    transform: rotate(180deg);
}

.button-selected {
    color: rgb(42, 51, 144);
    border: 1px solid rgb(42, 51, 144);
}

.button-unselected {
    color: rgb(162, 163, 166);
    border: 1px solid rgb(162, 163, 166);
}

.home-info-section-items {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0px 20px;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    padding-bottom: 5px;
}

.home-info-section-items::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.home-info-section-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0 10px;

    padding: 15px;
    min-width: 345px;
    max-width: 345px;
    height: 45px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(237, 237, 237);
    border-radius: 5px;
    overflow: hidden;
}

.home-info-section-search-input {
    font-weight: 300;
    font-size: 14px;
    outline: none;
    height: 100%;
    border: none;
    width: 100%;
    background-color: rgb(255, 255, 255);
}

.home-info-section-header {
    display: flex;
    flex-direction: row;
    gap: 0px 20px;
}

.home-info-section-header h2 {
    font-family: Mulish;
    font-size: 20px;
    font-weight: 700;
    padding: 0;
    margin: 0;
}

.home-info-our-blog {
    font-size: 12px;
    border: 1px solid black;
    border-radius: 5px;
    font-weight: 700;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.home-info-section-subheader {
    font-size: 14px;
    font-weight: 300;
    color:  rgb(162, 163, 166);
}

.home-blogs {
    display: flex;
    flex-direction: column;
    gap: 5px 0px;
}

.home-info-blog-items {
    display: flex;
    flex-direction: row;
    justify-content: stretch;

    gap: 0px 30px;
}

.home-bottom-decoration {
    display: flex;
    flex-direction: row;
    height: 8px;
    width: 100%;
}

.home-bottom-decoration .first {
    background: rgb(71, 191, 192);
    height: 100%;
    width: 100%;
}
.home-bottom-decoration .second {
    background: rgb(42, 51, 144);
    height: 100%;
    width: 100%;
}
.home-bottom-decoration .third {
    background: rgb(248, 30, 70);
    height: 100%;
    width: 100%;
}

.home-footer {
    margin-top: 80px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
}

.home-footer-divider {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

.home-footer-section-left {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px 0px;
}

.home-footer-onemedical {
    font-size: 15px;
    font-weight: 400;
    color: rgb(92, 95, 100);
}

.home-footer-socials {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0px 10px;
}

.home-footer-social {
    width: 35px;
    height: 35px;
    border-radius: 50%;

    background: rgba(42, 51, 144, 0.08);
    transition: 0.2s;
}

.home-footer-social img {
    width: 35px;
    height: 35px;
    transition: 0s;
}


.home-footer-social:hover {
    background: rgb(42, 51, 144);
    transition: 0.2s;

}

.home-footer-social:hover img {
    filter: brightness(100);
    transition: 0s;
}

.home-footer-badge {
    width: 150px;
    margin-top: 10px;
}

.home-footer-section-right {
    border-left: 1px solid rgb(230, 233, 238);
    display: flex;
    flex-direction: row;
    gap: 0px 90px;
    padding-left: 30px;
}

.home-footer-column-header {
    font-size: 19px;
    font-weight: 600;
}

.home-footer-column-items {
    list-style: none;

    display: flex;
    flex-direction: column;
    gap: 5px 0px;

    margin: 0;
    padding: 0;
    color: rgb(46, 105, 195);
    font-size: 16px;
    font-weight: 400;
}

.home-footer-row {
    display: flex;
    flex-direction: row;
    gap: 0px 90px;
}

@media (max-width: 1200px) {
    .home-container {
        padding-left: 100px;
        padding-right: 100px;
        align-items: center;
        justify-content: center;
    }

    .home-appointment-graphic {
        width: 420px;
        height: 340px;
    }

    .home-all-info-container {
        padding-left: 100px;
        padding-right: 100px;
    }

    .home-popular-specialties {
        width: 970px;
    }

    .home-popular-specialties-items {
        width: 970px;
    }

    .home-popular-specialties-item {
        width: 300px;
        height: 165px;
    }

    .home-info-section {
        width: 970px;
    }

    .home-footer-section-right {
        gap: 0px 50px;
    }

    .home-footer-row {
        gap: 0px 50px;
    }

    .home-footer-badge {
        width: 120px; 
    }
    
}

@media (max-width: 995px) {
    .home-container {
        padding-left: 200px;
        padding-right: 350px;
        align-items: center;
        justify-content: center;
    }

    .home-appointment-graphic {
        width: 300px;
        height: 243px;
        position: absolute;
        right: 5%;
        /* top: 0px; */
        top: 150px;
    }

    .home-all-info-container {
        padding-left: 350px;
        padding-right: 350px;
    }

    .home-popular-specialties {
        width: 750px;
    }

    .home-popular-specialties-items {
        width: 750px;
    }

    .home-popular-specialties-item {
        width: 224px;
        height: 165px;
    }

    .home-info-section {
        width: 750px;
    }

    .home-info-section-buttons-container {
        padding-bottom: 15px;
    }
    

    .home-info-section-scroll-buttons {
        display: none;
    }

    .home-footer-section-left {
        width: 162px;
    }

    .home-footer-section-right {
        gap: 30px 90px;
        flex-direction: column;
        width: 485px;
    }

    .home-footer-row {
        gap: 0px 0px;
        justify-content: space-between;
    }

    .home-footer-column {
        width: 100%;
        text-wrap: wrap;
    }

    .home-footer-socials {
        flex-wrap: wrap;
        width: 84px;
        height: 84px;
    }

    .home-footer-badge {
        width: 85px; 
    }
}

@media (max-width: 750px) {
    .home-container {
        align-items: center;
        justify-content: center;
        padding: 0;
    }

    .home-appointment-section {
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home-appointment-header {
        width: 95vw;
    }

    .home-appointment-header h1 {
        font-size: 24px;
        font-weight: 700;
        min-width: 100%;
        max-width: 100%;
        text-align: center;
    }

    .home-appointment-buttons {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
    }

    .home-appointment-visit {
        width: 100%;
        justify-content: center;
    }

    .home-appointment-row-container {
        width: 100%;
        flex-direction: column;
    }

    .home-appointment-speciality, .dropdown-toggle home-appointment-speciality-link, .home-appointment-speciality-text {
        width: 100%;
    }

    .home-appointment-search {
        margin-left: 0;
        margin-top: 20px;
        width: 100%;
        font-weight: 700;
    }

    .home-appointment-graphic {
        display: none;
    }

    .home-section-bottom-graphic {
        display: none;
    }
    
    .home-section-bottom-graphic-mobile {
        min-width: 100%;
        max-width: 100%;
        display: block;
    }

    .home-section-bottom-graphic-mobile svg {
        /* min-width: 100%; */
        /* max-width: 100%; */
        width: calc(100% + 20px);
        margin-left: -10px;
    }

    .home-all-info-container {
        /* padding-left: 350px; */
        /* padding-right: 350px; */
        padding: 0px;
        margin-top: 30px;
        width: 100%;
    }

    .home-popular-specialties {
        width: 95%;
    }

    .home-popular-specialties-items {
        width: 100%;
        gap: 15px 15px;
        justify-content: center;
    }

    .home-popular-specialties-item {
        /* width: 258px; */
        width: calc(50% - 15px);
        height: 165px;
    }

    .home-info-section {
        width: 95%;
    }

    .home-info-section-header {
        justify-content: space-between;
    }

    .home-info-section-buttons-container {
        padding-bottom: 15px;
    }

    .home-info-section-button {
        height: 100%;
    }

    .home-info-section-scroll-buttons {
        display: none;
    }

    .home-bottom-decoration {
        margin-top: 80px;
        height: 4px;
    }

    .home-footer {
        align-items: center;
    }

    .home-footer-divider {
        flex-direction: column;
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;
        gap: 20px 0px;
    }

    .home-footer-section-left {
        width: 100%;
        padding: 0;
    }

    .home-footer-section-right {
        padding-left: 0;
        gap: 30px 90px;
        flex-direction: column;
        border-left: 0;
        width: 100%;
    }

    .home-footer-row {
        min-width: 100%;
        gap: 0px 0px;
        justify-content: space-between;
    }

    .home-footer-column-items {
        gap: 10px 0px;
    }

    .home-footer-socials {
        flex-wrap: wrap;
        width: 100%;
        height: fit-content;
    }

    .home-footer-badge {
        display: block;
    }
}