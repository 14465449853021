.change-country-root {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.change-country-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 100%;
    
    padding-top: 25px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;

    height: 70px;
}

.change-country-header h2 {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    padding: 0;
    margin: 0;
}

.change-country-header img {
    scale: 1.2;
}


.change-country-list {
    display: flex;
    flex-direction: column;

    height: calc(100% - 70px);
    list-style: none;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.change-country-item {
    height: 75px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    width: 100%;
    border-bottom: 1px solid rgb(230, 233, 238);

    color: rgb(92, 95, 100);
}

.change-country-selected {
    background: rgb(71, 115, 208);
    color: white;
}

.change-country-flag {
    width: 25px;
    height: 25px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
    border: 1px solid white;
}

.change-country-item h3 {
    font-size: 17px;
    font-weight: 400;
    padding: 0;
    margin: 0;
}
