@media (max-width: 995px) {
    .hdoc-logo {
        width: 120px;
        height: 35px;
    }
}

@media (max-width: 750px) {
    .hdoc-logo {
        width: 129px;
        height: 40px;
    }
}