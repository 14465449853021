.locationpicker-dropdown {
    
}

.locationpicker-dropdown-button {
    border: 0;
    padding-left: 10px;
    padding-right: 10px;
}

.locationpicker-flag {
    width: 22px;
    height: 22px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
}

.locationpicker-menu-item {
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.locationpicker-item-flag {
    width: 22px;
    background-size: cover;
    background-repeat: no-repeat; 
}

.locationpicker-item-text {
    display: flex;
    margin: 0;
    padding: 0;
    padding-left: 10px;
}