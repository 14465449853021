.insurance-profile-root {
    height: 192px;
    min-width: 300px;
    max-width: 300px;

    display: flex;
    flex-direction: column;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(231, 233, 239);

    border-radius: 5px;
    box-shadow: rgba(93, 95, 101, 0.1) 0px 2px 4px;
    padding: 15px;
}

.insurance-profile-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.insurance-profile-name {
    font-size: 16px;
}

.insurance-profile-picture {
    width: 80px;
    height: 25px;
}

.insurance-profile-plans-container {
    margin-top: 20px;
    width: 100%;
}

.insurance-profile-plans {
    
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px 5px;
}

.insurance-profile-plan {
    color:  rgb(162, 163, 166);
    border: 1px solid rgb(162, 163, 166);
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 14px;
    font-weight: 300;
}

.show-more {
    border: 1px solid rgb(46, 105, 195);
    color: rgb(46, 105, 195);
    cursor: pointer;
}