@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

.navbar-root {
    background: white;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-root * {
    font-size: 15px;
    font-family: "Mulish";
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    color: rgb(92, 95, 100);
}

.navbar-container {
    background: white;
    padding-left: 15px;
    padding-right: 15px;
    width: 1195px;
    height: 60px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.navbar-header {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.navbar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.navbar-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.navbar-content {
    padding-left: 15px;
    padding-right: 15px;
}

.navbar-content-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    list-style: none;

    margin: 0px;
    padding: 0px;
}

.navbar-specialities {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-specialties-link {
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-decoration: none;
}

.navbar-specialities-dropdown {
    display: flex;
    flex-direction: row;
    padding: 20px;
}

.navbar-general-care, .navbar-sepcialized-care, .navbar-other-care {
    display: flex;
    flex-direction: column;
    color: black;
}

.navbar-specialities-itemlist {
    list-style: none;
    padding: 0;
    margin: 0;
}

.navbar-specialities-itemlist li a {
    color: rgb(161, 163, 166);
}

.navbar-fordoctors {
    padding: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.navbar-search {
    width: 342.312;
    height: 48px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-login {
    padding: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-signup {
    padding: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-signup-link {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    text-decoration: none;
    cursor: pointer;

    background-color: rgb(71, 191, 192);
    border-radius: 3px;
    border: 1px solid rgb(255,255,255);
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
    color: rgb(255,255,255);
    font-weight: 300;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
}

.navbar-signup-link:hover {
    background-color: rgb(75, 193, 194);
    transition: 0.2s;
}

.navbar-dropdown {
    padding-left: 15px;
    padding-right: 15px;


    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-arabic-link {
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 800;
}

@media (max-width: 1200px) {
    .navbar-container {
        max-width: 940px;
    }

    .navbar-search {
        width: 190px;
    }
}

@media (max-width: 995px) {
    .navbar-root {
        height: 75px;
    }
    .navbar-container {
        max-width: 750px;
    }

    .navbar-fordoctors {
        height: 20px;
        width: 90px;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 5px;
        padding-right: 5px;
    }
    
    .navbar-search {
        width: 140px;
    }

    .navbar-login {
        padding: 0;
        width: 50px;
    }

    .navbar-signup {
        padding: 0;
        height: 44px;
        width: 95px;
    }

    .navbar-arabic-link {
        padding-left: 5px;
    }
    
}

@media (max-width: 750px) {
    .navbar-root {
        height: 75px;
    }
    .navbar-container {
        max-width: 750px;
    }

    .navbar-fordoctors {
        height: 20px;
        width: 90px;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 5px;
        padding-right: 5px;
    }
    
    .navbar-search {
        width: 140px;
    }

    .navbar-login {
        padding: 0;
        width: 50px;
    }

    .navbar-signup {
        padding: 0;
        height: 44px;
        width: 95px;
    }

    .navbar-arabic-link {
        padding-left: 5px;
    }
    
}