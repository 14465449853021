.searchbar-root {
    height: 90%;
    width: 350px;
    border: 1px solid #2a3390;
    border-radius: 8px;
    box-shadow: rgb(196, 196, 196) 0px 3px 4px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.searchbar-icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
}

.searchbar-icon img {
    height: 60%;
    filter: opacity(0.5) ;
}

.searchbar-input-container {
    width: 90%;
}

.searchbar-input-form {
    width: 100%;
}

.searchbar-input-box {
    width: 100%;
    border: 0;
    font-size: 14px;
}

.searchbar-input-box:focus, textarea:focus, select:focus{
    outline: none;
}