.search-menu-root {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.search-menu-top-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;   
    height: 45px;  
    
    border-bottom: 1px solid rgb(230, 233, 238);
}

.search-menu-search-bar {
    width: 80%;
    height: 100%;
    padding-left: 12px;
    padding-right: 12px;
}

.search-menu-search-input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    
    color: rgb(40, 40, 40);
}

.search-menu-cancel {
    width: 20%;
    height: 50%;
    color: rgb(42, 51, 144);

    font-size: 14px;
    font-weight: 700;

    border: none;
    border-left: 1px solid rgb(230, 233, 238);

    background: transparent;
    text-align: start;
}