

.navbar-mobile-container {
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 6px;
}

.navbar-mobile-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0px 20px;
    list-style: none;
    margin: 0;
    padding: 0;
}

.navbar-mobile-menu-button {
    width: 34px;
    height: 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border: none;
    background: transparent;
}

.navbar-mobile-menu-button-stripe {
    width: 22px;
    height: 2px;
    background: rgb(136, 136, 136);
}

.navbar-mobile-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0px 15px;
    list-style: none;
    margin: 0;
    padding: 0;
}

.navbar-mobile-arabic-link {
    color: rgb(92, 95, 100);
    font-family: Mulish;
    font-size: 15px;
    font-weight: 700;
}

.navbar-mobile-search {
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgb(71, 191, 192);
    border-radius: 50%;
}

.navbar-mobile-search img {
    filter: invert(1);
}

.navbar-mobile-locationpicker-flag {
    width: 30px;
    height: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
}


.navbar-mobile-menu-shown, .navbar-mobile-search-shown, .navbar-mobile-country-shown {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: white;
}

.navbar-mobile-menu-hidden, .navbar-mobile-search-hidden, .navbar-mobile-country-hidden {
    display: none;
}