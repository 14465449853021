@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
* {
    font-family: Mulish;
}

.menu-root {
    width: 100%;
    height: 100%;
}


.menu-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.menu-top {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.menu-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 0px 10px;
}

.menu-close {
    width: 40px;
    height: 40px;
    border: none;
    background: transparent;
}

.menu-close img {
    width: 100%;
    height: 100%;
    scale: 1.5;
}

.menu-signup {
    color: rgb(42, 51, 144);
    border: 1px solid rgb(42, 51, 144);
    background: transparent;
    border-radius: 4px;
    width: 114px;
    height: 40px;
    font-size: 16px;
    font-weight: 300;
    transition: 0.2s;

}

.menu-signup:hover {
    color: white;
    background: rgb(42, 51, 144);
    transition: 0.2s;
}

.menu-login {
    color: white;
    border: none;
    background: rgb(42, 51, 144);
    border-radius: 4px;
    width: 114px;
    height: 40px;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 300;
}

.menu-login:hover {
    color: white;
    background: rgb(54, 65, 183);
    transition: 0.2s;
}

.menu-info {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;

}

.menu-info-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid rgb(230, 233, 238);
}

.menu-info-item h4 {
    color: rgb(92, 95, 100);
    font-size: 17px;
    font-weight: 400;
}

.menu-info-item img {
    width: 24px;
    height: 24px;

}

.menu-socials-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 15px 0px;
}

.menu-socials-container h3 {
    color: rgb(42, 51, 144);
    font-family: Mulish;
    font-size: 17px;
    font-weight: 400;
    margin-left: 10px;
}

.menu-socials {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0px 15px;
}

.menu-social {
    width: 35px;
    height: 35px;
    border-radius: 50%;

    background: rgba(42, 51, 144, 0.08);
    transition: 0.2s;
}

.menu-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
    padding-top: 25px;
    border-bottom: 1px solid rgb(230, 233, 238);
    height: 75px;
}

.menu-header img {
    width: 16px;
    height: 16px;
    transform: rotate(180deg);
    position: relative;
}

.menu-header h3 {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.menu-specialities-root {
    height: 100%;
}

.menu-list {
    display: flex;
    flex-direction: column;

    height: calc(100% - 75px);
    list-style: none;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.menu-list-item {
    width: 100%;
    border-bottom: 1px solid rgb(230, 233, 238);
}

.menu-list-item-button {
    text-align: left;
    width: 100%;
    padding: 20px;

    border: none;
    background: transparent;
}

.menu-list-item-button h4 {
    color: rgb(92, 95, 100);
    font-size: 17px;
    font-weight: 400;
}