@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

body {
  overflow-y: hidden;
  height: 100vh;
}

#root {
  height: 100%;
}

.App {
  /* min-height: 100vh; */
  height: 100%;
  overflow-y: scroll;
}

.app-navbar {
  display: block;
}
.app-navbar-mobile {
  display: none;
}

@media (max-width: 750px) {
  .app-navbar {
    display: none;
  }
  .app-navbar-mobile {
    display: block;
  }
}