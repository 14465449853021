.doctor-profile-root {
    width: 320px;
    max-width: 320px;
    min-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    box-shadow: rgba(85, 85, 85, 0.15) 0px 3px 6px;
    border-radius: 5px;
}

.doctor-profile-tags {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0px 4px;
}

.doctor-profile-tag-popular {
    color: rgb(42, 51, 144);
    border: 1px solid rgb(42, 51, 144);
    border-radius: 5px;
    padding: 2px 5px;
}

.doctor-profile-tag-popular h3 {
    font-weight: 400;
    font-size: 12px;
    padding: 0;
    margin: 0;
}

.doctor-profile-tag-video-visit {
    background: rgb(42, 51, 144);
    color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 5px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0px 5px;
}

.doctor-profile-tag-video-visit h3 {
    font-weight: 400;
    font-size: 9px;
    padding: 0;
    margin: 0;
}

.doctor-profile-personal-details-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-top: 15px;
}

.doctor-profile-personal-details-picture {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 1px solid white;
}

.doctor-profile-personal-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 10px;
}

.doctor-profile-personal-details-hearts {
    display: flex;
    flex-direction: row;
    gap: 0px 1px;
}

.heart {
    width: 15px;
    height: 12px;
}

.doctor-profile-personal-details-name {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    padding: 0;
    margin: 0;
    margin-top: 10px;
}

.doctor-profile-personal-details-profession {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    margin-top: 2px;
}

.doctor-profile-other-info {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-top: 10px;
    padding: 0;
    gap: 10px 0px;
}

.doctor-profile-other-info-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0px 2px;
}

.doctor-profile-other-info-item-icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.doctor-profile-other-info-item h5 {
    color: rgb(162, 163, 166);
    font-size: 13px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.doctor-profile-other-info-item a {
    text-decoration: none;
    font-size: 13px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: #2e69c3;
}

.doctor-profile-other-info-item a:hover {
    text-decoration: underline;
}

.doctor-profile-buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 0px 5px;
    margin-top: 40px;
}

.doctor-profile-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0px 2px;
}

.view-profile {
    border: 1px solid rgb(72, 191, 192);
    background: none;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    transition: 0.2s;
}
.view-profile h5 {
    color: rgb(72, 191, 192);
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    transition: 0.2s;
}

.view-profile:hover {
    background: rgb(72, 191, 192);
    transition: 0.2s;
}
.view-profile:hover h5 {
    color: white;
    transition: 0.2s;
}

.book-now {
    background: rgb(72, 191, 192);
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: none;
    border-radius: 5px;
}

.book-now h5 {
    color: rgb(255, 255, 255);
    font-size: 13px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}