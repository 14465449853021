.blog-profile-root {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: rgba(85, 85, 85, 0.25) 0px 4.5px 8.5px;
    min-width: 366px;
    max-width: 366px;
    height: 366px;
}

.blog-profile-picture-container {
    height: 190px;
    width: 100%;
    object-fit: contain;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

}

.blog-profile-picture {
    width: 100%;
    margin-top: -25%
}

.blog-profile-info {
    padding: 20px;
}

.blog-profile-info-header {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
}

.blog-profile-info-description {
    color: rgb(85, 85, 85);
    font-size: 16px;
    font-weight: 300;
}

.blog-profile-read-more {
    text-decoration: none;
    color: rgb(46, 105, 195);
}


@media (max-width: 1200px) {
    .blog-profile-root {
        min-width: 320px;
        max-width: 320px;
        height: 390px;
    }
}


@media (max-width: 995px) {
    .blog-profile-root {
        min-width: 320px;
        max-width: 320px;
        height: 355px;
    }
}